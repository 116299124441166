.mainmiddle {
  margin: 10px;

  @media screen and (min-width: 350px) {
    margin: 20px;
  }

  @media screen and (min-width: 660px) {
    margin: 30px;
  }

  @media screen and (min-width: 961px) {
    display: grid;
    grid-template-columns: 1fr 300px;
    column-gap: 50px;
  }

  @media screen and (min-width: 1140px) {
    max-width: 1190px;
  }

  @media screen and (min-width: 1380px) {
    margin: 30px auto;
  }
}
