@use '@on3/styles/base';

@use '@on3/styles/variables' as var;

@use '@on3/styles/mixins' as mix;

@use 'sass:color';

.pianoBottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #1d252c;
  text-align: center;

  @include mix.spaceY(var.$spacing-sm);

  header {
    font-size: 0.75rem;
    color: var.$neutral;

    @include mix.spaceY(var.$spacing-sm);
  }

  h2 {
    @extend %h2;

    color: var.$white;
  }

  footer {
    @include mix.flex($gap: '5px', $justify: 'center');

    color: var.$white;
  }
}

.blocker {
  display: block;
  visibility: visible;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(0 0 0 / 60%);
  height: 100vh;
  z-index: 99;
}
