@use '@on3/styles/variables' as var;

.sidebar {
  display: none;

  @media screen and (min-width: var.$sidebar-break) {
    display: block;
  }

  .trendingWrapper {
    padding: var.$spacing-regular 0;
  }
}

.stickycontainer {
  position: sticky;
  top: var.$on3-sticky-height-desktop;
}

@media screen and (min-width: var.$sidebar-break) {
  .articleWrapper {
    max-width: 810px;

    &.noAccess {
      grid-column: 1/3;
      margin: 0 auto;
    }
  }
}
