@use '@on3/styles/variables' as var;

.container {
  display: block;
}

.heading {
  padding: 6px 0;
  color: var.$gray-2;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  border-bottom: 2px solid var.$neutral;
  margin-bottom: 12px;
}

.articles {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (min-width: 961px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
  }
}
