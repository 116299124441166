@use '@on3/styles/variables' as var;

.block {
  background-color: #edf9fc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  @media screen and (min-width: 961px) {
    height: 44px;
  }
}

.nascarImage {
  width: 92px;
  height: 16px;

  @media screen and (min-width: 961px) {
    width: 115px;
    height: 20px;
  }
}
