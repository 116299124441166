@use '@on3/styles/variables' as var;

.container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;

  @media only screen and (min-width: 1024px) {
    justify-content: flex-end;
  }
}

.iconContainer {
  display: flex;
  gap: 5px;
}

.iconBorder {
  height: 36px;
  width: 36px;
  min-width: 36px;
}

.icon {
  fill: var.$black;
}

.readInAppButton {
  padding: 8px 12px;
  background-color: var.$focusBackground;
}

.readInApp {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var.$gray;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.65px;
  justify-self: flex-end;

  svg {
    width: 15px;
    height: 21px;
  }
}
