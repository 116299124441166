@use '@on3/styles/variables' as var;

.adminBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editlink {
  margin-bottom: 10px;
  display: block;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  @media screen and (min-width: 961px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
  }

  .heading {
    font-size: 27px;
    line-height: 29px;
    letter-spacing: 0.25px;

    @media screen and (min-width: 961px) {
      flex-grow: 1;
      font-size: 47px;
      line-height: 49px;
    }
  }
}

.topauthorblock {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var.$neutralGray;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  span {
    margin-right: 5px;
  }
}

.avatar {
  display: none;

  @media only screen and (min-width: 961px) {
    display: block;
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
}

.writtenby {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: var.$neutralGray;
  display: block;
}

.profile {
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.15px;
  display: block;
}

.xBlock {
  display: none;

  @media only screen and (min-width: 961px) {
    display: inline;
  }
}

.on3Icon {
  margin-left: 10px;
}

.twitter {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.15px;

  .svg {
    margin-right: 5px;
    width: 36px;
    height: 36px;

    path {
      fill: var(--primary-color, var.$on3primary);
    }
  }
}

.shareBlock {
  display: block;
}

.article {
  margin-bottom: 40px;

  &.scrollArticle {
    border-bottom: solid 1px #ddd;
    position: relative;

    &::after {
      content: 'Scroll to the next article\a \25BC';
      white-space: pre;
      display: inline-block;
      background: var.$white;
      position: relative;
      bottom: -25px;
      padding-right: 10px;
      color: #999;
      text-align: center;

      @media only screen and (min-width: 961px) {
        left: calc(50% - 75px);
        padding: 0 10px;
      }
    }

    .seperatorbar {
      display: block;
      position: absolute;
      bottom: -14px;
      right: 0;
      padding-left: 10px;
      background: var.$white;
    }
  }
}

.innerstyles {
  word-wrap: break-word;

  a {
    color: var(--primary-color, var.$on3primary);
  }

  h2 {
    margin: 15px auto;
    color: var.$night;
    font-family: var.$geist;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: 900;

    @media screen and (min-width: 961px) {
      font-size: 32px;
      line-height: 33px;
      letter-spacing: 0.25px;
    }
  }

  p {
    letter-spacing: 0.35px;
    color: var.$gray-2;
    font-family: var.$merriweather;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 20px;
    word-break: break-word;
  }

  ul,
  ol {
    padding-inline-start: 40px;
    margin-bottom: 20px;

    li {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.35px;
      font-family: var.$merriweather;
      word-break: break-word;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;

    li {
      margin-bottom: 5px;
    }
  }

  iframe {
    &[src*='hudl.com'] {
      min-width: 1%;
      max-width: 600px;
      width: 100%;

      @media only screen and (max-width: 640px) {
        max-height: 56vw;
      }
    }
  }

  > div:not(:empty) {
    margin-bottom: 48px;
  }

  :global(.bam-inline-promotion-block) {
    margin-bottom: 20px;
  }

  :global(bam-field),
  :global(.bam-field) {
    letter-spacing: 0.35px;
    color: #202733;
    font-family: merriweather, georgia, times, serif;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 20px;
    word-break: break-word;
  }

  :global(figure) {
    margin: 0 0 40px;
    display: block;
  }

  :global(figcaption) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
    margin-top: 10px;
    color: var.$gray;
  }

  :global(table) {
    display: block;
    border-collapse: collapse;
    overflow-x: auto;

    td,
    th {
      border: 1px solid var.$on3night;
      padding: 10px;
    }
  }

  :global(.wp-caption-text) {
    font-family: var.$merriweather;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
    color: var.$gray;
  }

  :global(.wpcaption),
  :global(.wp-block-embed__wrapper),
  :global(.wp-caption) {
    width: 100% !important;
    max-width: 100% !important;

    a {
      display: block;

      img {
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: 961px) {
      max-width: 800px;
    }
  }

  :global(.dataTables_wrapper) {
    overflow: scroll;
    clear: both;
    margin-bottom: 1em;
  }

  :global(.fluid-width-video-wrapper) {
    width: 100%;
    position: relative;
    padding: 0;
  }

  // Embed Iframes
  :global(.onthree-embed) {
    width: 100%;
    min-width: 300px;
  }

  :global(.wp-embed-aspect-16-9) {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    margin-bottom: 40px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  :global(.wp-embed-aspect-4-3) {
    position: relative;
    overflow: hidden;
    padding-top: 75%;
    margin-bottom: 40px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  :global(.wp-block-video video) {
    max-width: 100%;
  }

  // Live Blog Styles
  :global(.wp-block-blocks-on3-block-liveblogging) {
    border-bottom: 3px solid var.$lightNeutral;
    margin-bottom: var.$spacing-lg;

    &:first-of-type {
      position: relative;

      &::before {
        font-family: var.$geist;
        content: 'Updates';
        display: block;
        text-align: center;
        margin: var.$spacing-regular 0;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.15px;
        line-height: 2rem;
        border-bottom: 3px solid var(--primary-color, var.$on3primary);
      }

      &::after {
        display: block;
        content: '';
        opacity: 0.06;
        line-height: 2rem;
        background-color: var(--primary-color, var.$on3primary);
        position: absolute;
        inset: 0;
        height: 2rem;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }

    :global(.live-blog-post-author) {
      font-family: var.$geist;
      color: var.$gray;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      display: flex;
      gap: 5px;

      a {
        color: var.$gray;
      }
    }

    :global(.event-item-title) {
      font-family: var.$geist;
      font-size: 1.5rem;
      letter-spacing: 0.25px;
      margin: var.$spacing-sm 0;
    }

    :global(.blog-published-dateTime) {
      display: block;
      color: var.$gray;
      font-size: 0.75rem;
      letter-spacing: 0.35px;
      margin-bottom: var.$spacing-regular;
    }
  }

  // End Live Blog Styles
}

.figure {
  margin: 0 0 40px;
  display: block;
}

.featuredimage {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  max-width: 795px;
}

.videoWrapper {
  margin-bottom: 20px;
  padding-bottom: 56.25%;
  height: 0;
  position: relative;
}

.titleOverlay {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgb(0 0 0 / 50%);
  color: white;
  padding: 5px 10px;
  display: none;
  z-index: 1;

  &.visible {
    display: block;
  }
}

.caption {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15px;
  margin-top: 10px;
  color: var.$gray;
  text-align: center;
}

.restrict {
  max-height: 300px;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  overflow-y: hidden;

  body {
    background: #09f;
    transition: background 0.5s ease-out;
  }

  body:hover {
    background: #f90;
  }
}

.commentBlock {
  margin-top: 40px;
  border-top: solid 1px var.$lightGray;
  padding-top: 40px;
}

.commentHeader {
  border-bottom: 2px solid var.$neutral;
  line-height: 24px;
}

.commentInnerBlock {
  text-align: center;
  padding: 30px 0 48px;
}

.movedText {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.conversationText {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.65px;
  margin-bottom: 15px;
}

.boardButton {
  border-radius: 2px;
  padding: 12px 24px;
  background: var.$ksrBlue;
  box-shadow: -1px 1px 0 0 rgb(0 0 0 / 12%);
  display: inline-flex;
  align-items: center;
  gap: 10px;

  svg {
    fill: var.$white;
    width: 16px;
    height: 16px;
  }
}

.innerLink {
  color: var.$white;
}

.trending {
  max-width: 500px;
  margin: 0 auto;
  background-color: var.$focusBackground;
  padding: var.$spacing-sm;

  .signUp {
    margin-top: var.$spacing-regular;

    > div {
      padding: 0;
    }
  }

  @media screen and (min-width: 961px) {
    display: none;
  }

  // overrides applied from cms stylings
  a {
    color: var.$on3night;
  }

  p {
    margin-bottom: 0;
  }

  ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.articleWrapper {
  :global(.regMetered) .innerstyles {
    max-height: 300px;
    overflow: hidden;
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    overflow-y: hidden;

    body {
      background: #09f;
      transition: background 0.5s ease-out;
    }

    body:hover {
      background: #f90;
    }
  }
}
