@use '@on3/styles/variables' as var;

.articleContainer {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;

  @media only screen and (min-width: 961px) {
    flex-direction: row;
  }

  &:nth-of-type(5n + 1) {
    flex-direction: column;
  }

  @media only screen and (min-width: 961px) {
    &:nth-of-type(5n + 1),
    &:nth-of-type(5n + 2) {
      flex-direction: column;
    }
  }

  .articleImageLink,
  .articleImage {
    object-fit: cover;
    display: block;
    width: 100px;
    height: 100px;
    min-width: 100px;
    border-radius: 3px;

    @media only screen and (min-width: 961px) {
      width: 160px;
      height: 110px;
      min-width: 160px;
    }
  }

  &:nth-of-type(5n + 1) .articleImage,
  &:nth-of-type(5n + 1) .articleImageLink {
    width: 345px;
    height: 185px;
  }

  @media only screen and (min-width: 961px) {
    &:nth-of-type(5n + 1) .articleImage,
    &:nth-of-type(5n + 2) .articleImage,
    &:nth-of-type(5n + 1) .articleImageLink,
    &:nth-of-type(5n + 2) .articleImageLink {
      width: 393px;
      height: 215px;
    }
  }

  .articleItemContainer {
    flex-grow: 1;
  }

  .category {
    display: block;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1.15px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15px;
    margin-bottom: 10px;

    @media only screen and (min-width: 961px) {
      font-size: 15px;
      line-height: 18px;
    }
  }

  &:nth-of-type(5n + 1) .heading {
    font-size: 17px;
    line-height: 18px;
  }

  @media only screen and (min-width: 961px) {
    &:nth-of-type(5n + 1) .heading,
    &:nth-of-type(5n + 2) .heading {
      font-size: 19px;
    }
  }

  .articleAuthorContainer {
    display: flex;
    align-items: center;
    font-size: 10px;
    gap: 4px;
    color: var.$neutralGray;
    margin-bottom: 10px;
  }

  .author,
  .date {
    font-size: 10px;
    color: var.$gray;
  }

  .articleBody {
    display: none;
    color: var.$gray;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.65px;
    word-break: break-word;
  }

  &:nth-of-type(5n + 1) .articleBody {
    display: block;
  }

  @media only screen and (min-width: 961px) {
    &:nth-of-type(5n + 2) .articleBody {
      display: block;
    }
  }

  &:nth-of-type(5n + 3),
  &:nth-of-type(5n + 4),
  &:nth-of-type(5n + 5) {
    grid-column: span 2;
  }
}
