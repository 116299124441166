.ad {
  display: none;

  @media only screen and (min-width: 961px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 768px;
    min-height: 90px;
    margin: 0 auto 25px;
  }
}
